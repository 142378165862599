<script>
import TablePage from '../../../../../../components/table_page';
import Form from '../form';
import request from '../../../../../../utils/request.js';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps-mall/v1/commodity/findByCondition',
      formName: 'Form',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      tablePage: { // 分页
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },

    };
  },
  components: {
    Form,
  },
  mounted() {
    this.getConfigList('cps_integral_goods_management_list').then((res) => {
      res.map((v) => {
        const rowData = v;
        if (!rowData.field) {
          rowData.className = 'formatter-image-mall';
        }
        return rowData;
      });
    });
  },
  methods: {
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable') {
        if (this.selection.length === 0) {
          this.$message({
            type: 'info',
            message: '请至少选择一条数据',
          });
          return false;
        }
        const params = this.selection.map((item) => ({
          id: item,
          status: val.code === 'enable' ? 1 : 0,
        }));
        request.patch('/cps-mall/v1/commodity/updateStatus', params).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
        return false;
      }
      return true;
    },
    // 按钮点击事件
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formName = 'Form';
        this.formConfig = {
          code: 'add',
        };
        this.modalConfig.title = '新增';
        this.openFull();
      }
      if (val.code === 'edit') {
        // 如果分类层级为启用状态则不可编辑
        if (row.fullName === '1111') {
          this.formConfig = { ...row, code: 'view' };
        } else {
          this.formConfig = { ...row, code: 'edit' };
        }
        this.formName = 'Form';
        this.modalConfig.title = '编辑';
        this.openFull();
      }
      // 查看
      if (val.code === 'view') {
        this.formName = 'Form';
        this.formConfig = { ...row, btnCode: 'view', code: 'view' };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    // 设置图片
    setColumn(col) {
      const rowData = col;

      rowData.className = 'formatter-image-mall';
      if (rowData.field === 'pictureForList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => `<img src="${cellValue}" />`;
      }
      if (rowData.field === 'classId') {
        // console.log(rowData);
        rowData.apiUrl = '/cps-mall/v1/commodity/classification/findList';
        rowData.methods = 'get';
        rowData.optionsKey = {
          value: 'id',
          label: 'name',
        };
        rowData.formatter = ({ row }) => row.className;
      }
      return rowData;
    },

  },
};
</script>
