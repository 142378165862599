var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "vxe-select",
                {
                  staticClass: "w80",
                  attrs: {
                    placeholder: "请选择积分商品类型",
                    clearable: "",
                    disabled: _vm.disabled,
                  },
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                _vm._l(_vm.TypeOptions, function (item) {
                  return _c("vxe-option", {
                    key: item.dictCode,
                    attrs: { value: item.dictCode, label: item.dictValue },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.type === 1 && !_vm.disabled,
                  expression: "type===1 && !disabled",
                },
              ],
              staticClass: "flex",
              attrs: { span: 16 },
            },
            [
              _vm._v(" 导入劵码： "),
              _c(
                "el-link",
                { attrs: { type: "primary" }, on: { click: _vm.download } },
                [_vm._v("下载模板")]
              ),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo mrl20",
                  attrs: {
                    action: "",
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "file-list": _vm.fileList,
                    "auto-upload": false,
                    limit: 1,
                    "on-exceed": _vm.onExceed,
                    "on-change": _vm.onChange,
                  },
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { slot: "trigger", type: "primary" },
                      slot: "trigger",
                    },
                    [_vm._v("导入劵码")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }