<script>
// import tablePageTreeBase from '../../../../components/table_page_tree_base';
import TablePage from './table';
// import TablePage from '../../../../components/table_page';

export default {
  extends: TablePage,
  components: {
    TablePage,
  },
  data() {
    return {
    };
  },
};
</script>
