<template>
  <div>
    <el-row>
      <el-col :span="7">
        <vxe-select v-model="type" placeholder="请选择积分商品类型" clearable class="w80" @change="changeType" :disabled="disabled">
          <vxe-option v-for="(item) in TypeOptions" :key="item.dictCode" :value="item.dictCode" :label="item.dictValue"></vxe-option>
        </vxe-select>
      </el-col>
      <el-col :span="16" v-show="type===1 && !disabled" class="flex">
        导入劵码：
        <el-link type="primary" @click="download">下载模板</el-link>
        <el-upload class="upload-demo mrl20" ref="upload" action="" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :auto-upload="false" :limit="1" :on-exceed="onExceed" :on-change="onChange">
          <!-- <el-button  size="small" type="primary"></el-button> -->
        <el-link type="primary" slot="trigger">导入劵码</el-link>
        </el-upload>
      </el-col>
      <!-- <el-col :span="8" v-show="type===1 && fileName">
        <span>{{fileName}}</span>
        <el-button type="danger" icon="el-icon-delete" circle class="mrl20"></el-button>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import request from '../../../../../../../utils/request.js';

export default {
  name: 'couponCode',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      TypeOptions: [
        { dictCode: 0, dictValue: '实体' },
        // { dictCode: 1, dictValue: '虚拟' },
      ],
      type: '',
      fileList: [],
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = [];
      this.$emit('input', { type: this.type, file: null });
    },
    handlePreview(file) {
      console.log(file);
    },
    onExceed(files) {
      this.$message({
        type: 'info',
        message: '请删除后在上传',
      });
    },
    onChange(file, fileList) {
      console.log(file);
      this.$emit('input', { type: this.type, file });
    },
    changeType() {
      this.$emit('input', { type: this.type, file: null });
    },
    download() {
      const a = document.createElement('a');
      a.href = '/cps-mall/v1/commodity/virtual/model';
      document.body.appendChild(a);
      a.click(); // 下载
      URL.revokeObjectURL(a.href); // 释放URL 对象
      document.body.removeChild(a); // 删除 a 标签
      // request
      //   .get('/cps-mall/v1/commodity/virtual/model')
      //   .then((res) => {
      //     // if (res.success) {

      //     // }
      //   });
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        console.log(v);
        if (v) {
          this.type = v.type;
        }
        // this.value = this.value ? this.value : [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  width: 80%;
  height: 32px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.mrl20 {
  margin-left: 20px;
}
.flex{
  display: flex
}
/deep/ .el-link{
  align-items: flex-start
}
</style>
