<!--
 * @Author: 冯杰
 * @Date: 2021-09-22 13:59:34
 * @LastEditTime: 2021-09-29 15:29:45
 * @FileName: 文件名
-->
<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request.js';
import CouponCode from '../components/coupon_code';

formCreate.component('couponCode', CouponCode);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [], // 渲染表单
      myUrl: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg',
    };
  },
  async created() {
    await this.getFormRule('cps_integral_goods_management_form');
    const pictureForList = this.getRule('pictureForList');
    pictureForList.props.limit = 1;
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'price') {
        item.props = {
          ...item.props,
          precision: 0,
          controls: false,
        };
      }
      return item;
    },
    async formComplete() {
      const classId = this.getRule('classId');
      const classIdOptions = await request.get(
        '/cps-mall/v1/commodity/classification/findList',
      );
      const option = await classIdOptions.result.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      classId.options = option;
      this.getDetail();
    },
    getDetail() {
      if (this.formConfig.code === 'add') return;
      request.get(`/cps-mall/v1/commodity/${this.formConfig.id}`).then((res) => {
        if (res.success) {
          const { result } = res;
          this.setValue({
            ...result,
            couponCode: { type: result.type },
            pictureForCarousel: result.pictureForCarousel ? result.pictureForCarousel.split(',') : [],
            pictureForDetails: result.pictureForDetails ? result.pictureForDetails.split(',') : [],
          });
        }
      });
    },
    submit() {
      let params = this.getFormData();
      console.log(params);
      if (this.formConfig.code === 'add') {
        params = {
          ...params,
          type: params.couponCode.type,
          file: params.couponCode.file,
        };
      } else {
        params = {
          ...params,
          type: params.couponCode.type,
          file: params.couponCode.file,
          id: this.formConfig.id,
        };
      }
      request.post('/cps-mall/v1/commodity', params).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
